import basePageFields from '@fragments/pages/basePageFields';

import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment PodcastFragment on Podcast {
        ${basePageFields}
        contributors {
            ...PersonSummaryFragment
        }
        dek
        hero {
            image {
                ...ImageSummaryFragment
            }
        }
        podcastNetworks {
            network
            subscribeLink {
                type
                label
                url
            }
        }
        syndications {
            programs {
                reference {
                    title
                    path
                    dek
                    contributors {
                        avatar {
                            ...AvatarFragment
                        }
                    }
                }
                isPrimary
            }
            centers {
                global {
                    syndicated
                    isPrimary
                    topics {
                        title
                        path
                    }
                    regions {
                        title
                        path
                    }
                }
                middleEast {
                    syndicated
                    isPrimary
                    topics {
                        title
                        path
                    }
                    regions {
                        title
                        path
                    }
                }
                russiaEurasia {
                    syndicated
                    isPrimary
                    topics {
                        title
                        path
                    }
                    regions {
                        title
                        path
                    }
                }
                india {
                    syndicated
                    isPrimary
                    topics {
                        title
                        path
                    }
                    regions {
                        title
                        path
                    }
                }
                china {
                    syndicated
                    isPrimary
                    topics {
                        title
                        path
                    }
                    regions {
                        title
                        path
                    }
                }
                europe {
                    syndicated
                    isPrimary
                    topics {
                        title
                        path
                    }
                    regions {
                        title
                        path
                    }
                }
            }
        }
        seo {
            ...SeoFragment
        }
    }

    ${ImageSummaryFragment}
    ${PersonSummaryFragment}
    ${SeoFragment}
`;
